import React, { useState, useEffect } from "react";

export default function Heading() {
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch("https://demo.tessaract.eticloud.uk/logo");
        if (response.ok) {
          const data = await response.json();
          setLogoUrl(data.logoUrl);
        } else {
          setLogoUrl(null);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
        setLogoUrl(null);
      }
    };
    fetchLogo();
  }, []);

  return <img src={logoUrl} className="mt-4 header-image"></img>;
}
